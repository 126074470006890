import type { ReactNode } from 'react';
import { PopoverContext } from './PopoverContext';
import type { PopoverContextValue } from './types';

type Props = {
  children: ReactNode;
} & Pick<PopoverContextValue, 'isOpen' | 'onClose'>;

export const PopoverContextProvider = ({
  isOpen,
  onClose,
  children,
}: Props) => {
  const value: PopoverContextValue = {
    isOpen,
    onClose,
  };

  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  );
};
