import { createContext } from '@/utils';
import type { PopoverContextValue } from './types';

export const PopoverContext = createContext<PopoverContextValue>(
  {
    isOpen: true,
    onClose: () => {
      // Overridden by provider
    },
  },
  'PopoverContext'
);
