export const defaultFeatureValues = {
  icsSharingEnabled: false,
  googleCalendarIntegrationEnabled: false,
  requiredVersion: '0.0.0',
  scheduleDescriptionEnabled: false,
  hideItemsEnabled: false,
  snippetsEnabled: false,
  sourcesEnabled: false,
  rsvpEnabled: false,
  copyScheduleEnabled: false,
  quickCreateMaintenanceModeEnabled: false,
  disableAllAiFeatures: false,
  conflictScheduleEnabled: false,
  bannerMessage: '',
  bannerLink: '',
  updateScheduleMetadataEnabled: false,
  agendaHeroScheduleAsSource: false,
  magicResultsInPlatformEnabled: false,
};

export type FeatureFlag = keyof typeof defaultFeatureValues;
