import { type ReactNode, useMemo } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import useEvent from 'react-use-event-hook';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useScheduleContext } from '../ScheduleContext';
import {
  ScheduleFiltersContext,
  type ScheduleFiltersContextValue,
  type ScheduleFilters,
} from './ScheduleFiltersContext';

export const ScheduleFiltersContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { schedule } = useScheduleContext();
  const { trackEvent } = useAnalytics();

  const filterIds = useMemo(() => {
    const filters: ScheduleFilters = {
      categories: [],
      labels: [],
      whoLabels: [],
      feeds: [],
    };
    const categories = searchParams.get('categories');
    if (categories) {
      filters.categories = categories.split(',');
    }
    const labels = searchParams.get('labels');
    if (labels) {
      filters.labels = labels.split(',');
    }
    const whoParams = searchParams.get('who');
    if (whoParams) {
      filters.whoLabels = whoParams.split(',');
    }
    const feeds = searchParams.get('feeds');
    if (feeds) {
      filters.feeds = feeds.split(',');
    }
    return filters;
  }, [searchParams]);

  const {
    filteredCategories,
    filteredWhoLabels,
    filteredLabels,
    filteredFeeds,
  } = useMemo(() => {
    const filteredCategories =
      schedule?.categories.filter((category) =>
        filterIds.categories.includes(category.id)
      ) ?? [];
    const filteredWhoLabels =
      schedule?.whoLabels.filter((label) =>
        filterIds.whoLabels.includes(label.id)
      ) ?? [];
    const filteredLabels =
      schedule?.labels.filter((label) => filterIds.labels.includes(label.id)) ??
      [];
    const filteredFeeds =
      schedule?.feeds.filter((feed) => filterIds.feeds.includes(feed.id)) ?? [];
    return {
      filteredCategories,
      filteredWhoLabels,
      filteredLabels,
      filteredFeeds,
    };
  }, [schedule, filterIds]);

  const hasAppliedFilters =
    !!filteredCategories.length ||
    !!filteredWhoLabels.length ||
    !!filteredLabels.length ||
    !!filterIds.feeds.length;

  const setFilters = useEvent(
    ({ categories, labels, whoLabels, feeds }: ScheduleFilters) => {
      const newParams = createSearchParams(Object.fromEntries(searchParams));
      if (!categories.length) {
        newParams.delete('categories');
      } else {
        newParams.set('categories', categories.join(','));
      }
      if (!labels.length) {
        newParams.delete('labels');
      } else {
        newParams.set('labels', labels.join(','));
      }
      if (!whoLabels.length) {
        newParams.delete('who');
      } else {
        newParams.set('who', whoLabels.join(','));
      }
      if (!feeds.length) {
        newParams.delete('feeds');
      } else {
        newParams.set('feeds', feeds.join(','));
      }
      setSearchParams(newParams);
      trackEvent('schedule:focus-update');
    }
  );

  const clearFilters = useEvent(() => {
    const newParams = createSearchParams(Object.fromEntries(searchParams));
    newParams.delete('categories');
    newParams.delete('labels');
    newParams.delete('who');
    newParams.delete('feeds');
    setSearchParams(newParams);
    trackEvent('schedule:focus-clear');
  });

  const removeCategory = useEvent((categoryId: string) => {
    const newCategories = filterIds.categories.filter(
      (id) => id !== categoryId
    );
    setFilters({ ...filterIds, categories: newCategories });
  });

  const removeLabel = useEvent((labelId: string) => {
    const newLabels = filterIds.labels.filter((id) => id !== labelId);
    setFilters({ ...filterIds, labels: newLabels });
  });

  const removeWhoLabel = useEvent((labelId: string) => {
    const newWhoLabels = filterIds.whoLabels.filter((id) => id !== labelId);
    setFilters({ ...filterIds, whoLabels: newWhoLabels });
  });

  const value: ScheduleFiltersContextValue = useMemo(
    () => ({
      filterIds,
      filteredCategories,
      filteredLabels,
      filteredWhoLabels,
      filteredFeeds,
      hasAppliedFilters,
      setFilters,
      clearFilters,
      removeLabel,
      removeCategory,
      removeWhoLabel,
    }),
    [
      filterIds,
      filteredCategories,
      filteredLabels,
      filteredWhoLabels,
      filteredFeeds,
      hasAppliedFilters,
      clearFilters,
      setFilters,
      removeCategory,
      removeLabel,
      removeWhoLabel,
    ]
  );

  return (
    <ScheduleFiltersContext.Provider value={value}>
      {children}
    </ScheduleFiltersContext.Provider>
  );
};
