import { css, Global } from '@emotion/react';
import type { ReactNode } from 'react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  forwardRef,
  type PlacementWithLogical,
  type PopoverContentProps,
  Portal,
} from '@/ui';
import { EventCapture } from './EventCapture';
import { Popper, PopoverTrigger, PopoverBody, PopoverContent } from './Parts';
import { PopoverContextProvider } from './PopoverContextProvider';

type Props = {
  isOpen: boolean;
  trigger: JSX.Element;
  children?: ReactNode;
  customHeader?: ReactNode;
  onClose: () => void;
  contentProps?: PopoverContentProps & { 'data-testid'?: string };
};

export const Popover = forwardRef(
  ({ isOpen, trigger, children, onClose, contentProps }: Props, ref) => {
    const isMobileBreakpoint = useIsMobileBreakpoint();

    if (isMobileBreakpoint) {
      // children should always be a modal via PopoverPanel
      return (
        <>
          {trigger}

          <Portal>
            <PopoverContextProvider isOpen={isOpen} onClose={onClose}>
              {children}
            </PopoverContextProvider>
          </Portal>
        </>
      );
    }

    // Desktop popover
    return (
      <>
        {/* Hack ensures the popovers will overflow the entry modal */}
        <Global
          styles={css`
            .chakra-popover__popper {
              z-index: calc(var(--chakra-zIndices-modal) + 1) !important;
            }
          `}
        />
        <Popper
          isLazy
          isOpen={isOpen}
          placement="bottom-start"
          modifiers={[
            {
              name: 'preventOverflow',
              options: {
                mainAxis: true,
                altAxis: true,
              },
            },
            {
              name: 'offset',
              options: {
                offset: ({
                  placement,
                }: {
                  placement: PlacementWithLogical;
                }) => {
                  if (placement === 'bottom-start') {
                    return [32, 8];
                  } else {
                    return [];
                  }
                },
              },
            },
          ]}
          onClose={onClose}
        >
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <Portal>
            <EventCapture>
              <PopoverContent
                borderRadius="10"
                borderWidth="0"
                ref={ref}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    onClose();
                  }
                }}
                {...contentProps}
              >
                <PopoverBody
                  borderRadius="10"
                  boxShadow="0px 0px 8px 2px rgba(0, 0, 0, 0.1)"
                  padding="0"
                >
                  <PopoverContextProvider isOpen={isOpen} onClose={onClose}>
                    {children}
                  </PopoverContextProvider>
                </PopoverBody>
              </PopoverContent>
            </EventCapture>
          </Portal>
        </Popper>
      </>
    );
  }
);
