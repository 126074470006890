import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { TIME_ZONE_UTC } from '@/constants';
import { gqlClient } from '@/lib';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { datePartInZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import type {
  UpdateQuickEntryMutation,
  UpdateQuickEntryMutationVariables,
} from './useUpdateQuickEntry.generated';

const query = gql`
  mutation UpdateQuickEntry($input: UpdateQuickEntryInput!) {
    updateQuickEntry(input: $input) {
      id
      title
      emoji
      description
      timeZone
      locationWithPlace {
        name
        googlePlaceId
      }
      recurrences {
        startDate
        endDate
        isOnDay
        rule
      }
    }
  }
`;

export const useUpdateQuickEntry = () => {
  const { mutate, ...rest } = useMutation<
    UpdateQuickEntryMutation,
    QueryError,
    UpdateQuickEntryMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateQuickEntryMutation,
        UpdateQuickEntryMutationVariables
      >(query, variables),
  });

  const updateQuickEntry = useCallback(
    (
      entry: DecoratedQuickCreateEntry,
      options?: MutateOptions<
        UpdateQuickEntryMutation,
        QueryError,
        UpdateQuickEntryMutationVariables
      >
    ) => {
      const { startDate, endDate, isOnDay, rule, ...entryWithoutRecurrence } =
        entry;

      const recurrence = {
        startDate: isOnDay
          ? datePartInZone(startDate, TIME_ZONE_UTC).toISO()
          : startDate.toUTC().toISO(),
        endDate: isOnDay
          ? datePartInZone(endDate, TIME_ZONE_UTC).toISO()
          : endDate.toUTC().toISO(),
        isOnDay,
        rule,
      };

      const input: UpdateQuickEntryMutationVariables['input'] = {
        ...entryWithoutRecurrence,
        recurrences: [recurrence],
      };
      return mutate({ input }, options);
    },
    [mutate]
  );

  return { updateQuickEntry, ...rest };
};
