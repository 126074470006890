import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { createContext } from '@/utils';

type ItemCardContextValue = {
  entry: DecoratedEntry;
  instance?: DecoratedInstance;
};

export const ItemCardContext = createContext<ItemCardContextValue | undefined>(
  undefined,
  'ItemCardContext'
);
