import { JobStatus } from '@/types/gql.generated';
import type { Job } from '../../../types';

/** Did the job error out and we fell back to a single event on today's date? */
export const didJobUseFallback = (job: Job | undefined | null): boolean => {
  return (
    !!job?.errorCode &&
    (job.status === JobStatus.Completed || job.status === JobStatus.Reviewed)
  );
};
