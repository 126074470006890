import { createContext } from '@/utils';
import type { DecoratedSchedule } from '../ScheduleContext';

export type ScheduleFilters = {
  categories: string[];
  labels: string[];
  whoLabels: string[];
  feeds: string[];
};

export type ScheduleFiltersContextValue = {
  filterIds: ScheduleFilters;
  filteredCategories: DecoratedSchedule['categories'];
  filteredLabels: DecoratedSchedule['labels'];
  filteredWhoLabels: DecoratedSchedule['labels'];
  filteredFeeds: DecoratedSchedule['feeds'];
  hasAppliedFilters: boolean;
  setFilters: (filters: ScheduleFilters) => void;
  clearFilters: () => void;
  removeCategory: (categoryId: string) => void;
  removeLabel: (labelId: string) => void;
  removeWhoLabel: (labelId: string) => void;
};

export const ScheduleFiltersContext = createContext<
  ScheduleFiltersContextValue | undefined
>(undefined, 'ScheduleFiltersContext');
