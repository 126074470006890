import {
  type CalendarEvent,
  google,
  ics,
  office365,
  outlook,
  office365Mobile,
  outlookMobile,
} from 'calendar-link';
import { config } from '@/config';
import type { PlatformExport } from '@/pages/QuickCreate/hooks/usePlatformExports';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { PlatformTypes } from '@/types/gql.generated';

export type LabelOptions = {
  success?: boolean;
  platform: PlatformTypes;
  label?: string;
  isMobileBreakpoint: boolean;
  preferredPlatform: PlatformTypes | null;
  isPinned?: boolean;
};

type SuccessOptions = Pick<
  LabelOptions,
  'isMobileBreakpoint' | 'platform' | 'preferredPlatform'
>;

export const getStandardLabel = (platform: PlatformTypes): string => {
  if (platform === PlatformTypes.AgendaHero) {
    return 'Add to Agenda Hero';
  }
  if (platform === PlatformTypes.AppleIcs) {
    return 'Add to Apple';
  }
  if (platform === PlatformTypes.Email) {
    return 'Email invite';
  }
  if (
    platform === PlatformTypes.GoogleIntegration ||
    platform === PlatformTypes.GoogleLink
  ) {
    return 'Add to Google';
  }
  if (platform === PlatformTypes.OfficeLink) {
    return 'Add to Office 365';
  }
  if (platform === PlatformTypes.OutlookLink) {
    return 'Add to Outlook';
  }

  return 'Add to calendar';
};

const getSuccessLabel = ({
  isMobileBreakpoint,
  platform,
  preferredPlatform,
}: SuccessOptions): string => {
  if (isMobileBreakpoint || preferredPlatform) {
    return '';
  }
  if (platform === PlatformTypes.Email) {
    return 'Sent! 🎉';
  }
  return 'Added! 🎉';
};

export const getLabel = (options: LabelOptions): string => {
  const { label, success } = options;

  if (label) {
    return label;
  }
  return success
    ? getSuccessLabel(options)
    : getStandardLabel(options.platform);
};

const truncate = (text: string, length: number) => {
  return text.length > length ? `${text.substring(0, length)}...` : text;
};

export const getPlatformAddUrl = (
  entry: DecoratedQuickCreateEntry,
  provider: PlatformTypes,
  isMobile: boolean
): string | null => {
  if (entry.title) {
    const footer =
      provider === PlatformTypes.AppleIcs
        ? `\n\n---\nCreate calendar events with Agenda Hero Magic: ${config.server.url}/magic.`
        : `<p>---</p><p>Create calendar events with <a href="${config.server.url}">Agenda Hero Magic</a></p>`;

    let description = entry.description ?? entry.title;
    if (provider === PlatformTypes.AppleIcs) {
      const doc = new DOMParser().parseFromString(description, 'text/html');
      description = (doc.body.textContent || '').replace(/\s+/g, ' ').trim();
    }

    description = truncate(description, 1500) + footer;

    const props: CalendarEvent = {
      title: entry.emoji ? `${entry.emoji} ${entry.title}` : entry.title,
      description,
      start: entry.startDate,
      end: entry.endDate,
      allDay: entry.isOnDay,
      rRule: entry.rule ? entry.rule.replace('RRULE:', '') : undefined,
      location: entry.locationWithPlace?.name || undefined,
    };

    // Office/Office 365 requires different end dates than other platforms but only
    // on mobile. A single day all-day event should start and end on the same day.
    if (
      isMobile &&
      entry.isOnDay &&
      [PlatformTypes.OutlookLink, PlatformTypes.OfficeLink].includes(provider)
    ) {
      props.end = entry.endDate.minus({ days: 1 });
    }

    if (provider === PlatformTypes.GoogleLink) {
      return google(props);
    } else if (provider === PlatformTypes.OutlookLink) {
      return isMobile ? outlookMobile(props) : outlook(props);
    } else if (provider === PlatformTypes.OfficeLink) {
      return isMobile ? office365Mobile(props) : office365(props);
    } else if (provider === PlatformTypes.AppleIcs) {
      return ics(props);
    } else if (provider === PlatformTypes.OtherIcs) {
      return ics(props);
    }
  }
  return null;
};

type SendState = Record<PlatformTypes, boolean>;

export const getInitialSendState = (): SendState => {
  return Object.values(PlatformTypes).reduce<
    Partial<Record<PlatformTypes, boolean>>
  >((idx, type) => ({ ...idx, [type]: false }), {}) as SendState;
};

export const isOtherPlatform = (platform: PlatformTypes): boolean => {
  return (
    platform !== PlatformTypes.AgendaHero && platform !== PlatformTypes.Email
  );
};

export const hasSentToOther = (
  sends: SendState,
  preferredPlatform: PlatformTypes | null
): boolean => {
  return Object.keys(sends).some((key) => {
    const platform = key as PlatformTypes;
    return (
      sends[platform] &&
      isOtherPlatform(platform) &&
      platform !== preferredPlatform
    );
  });
};

export const hasDrawer = (platform: PlatformTypes): boolean => {
  return [
    PlatformTypes.AgendaHero,
    PlatformTypes.Email,
    PlatformTypes.GoogleIntegration,
  ].includes(platform);
};

export const hasExported = (
  adds: PlatformExport[],
  entry: DecoratedQuickCreateEntry,
  platform: PlatformTypes
): boolean => {
  return adds.some(
    (item) =>
      item.platform === platform &&
      item.entryExports.some((e) => e.entry.id === entry.id)
  );
};

export const hasExportedToOther = (
  adds: PlatformExport[],
  entry: DecoratedQuickCreateEntry,
  preferredPlatform: PlatformTypes | null
): boolean => {
  const sends = adds
    .filter((platformExport) =>
      platformExport.entryExports.some((item) => item.entry.id === entry.id)
    )
    .reduce<SendState>((state, add) => {
      return {
        ...state,
        [add.platform]: true,
      };
    }, getInitialSendState());

  return hasSentToOther(sends, preferredPlatform);
};
