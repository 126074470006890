import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';

type State = {
  lastEmail: string;
  setLastEmail: (email: string) => void;
};

const useStore = createWithEqualityFn<State>()(
  persist(
    (set) => ({
      lastEmail: '',
      setLastEmail: (lastEmail) => set({ lastEmail }),
    }),
    {
      name: '_ah_last_magic_email',
    }
  ),
  shallow
);

export const useLastSendToEmail = (): State => {
  const { currentUser } = useCurrentUserContext();
  const { lastEmail, setLastEmail } = useStore();
  const email = lastEmail || currentUser?.email || '';

  return { lastEmail: email, setLastEmail };
};
