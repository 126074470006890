import { Alert, AlertTitle, Text } from '@/ui';

export const FallbackNotice = () => {
  return (
    <Alert alignItems="flex-start" flexDir="column" variant="purple">
      <AlertTitle fontSize="18px" mb="2">
        Something went a bit sideways 🙈
      </AlertTitle>
      <Text mb="2">
        We weren&apos;t able to convert your events, so we created an event for
        today to keep things rolling. You can adjust any details on this event
        as needed.
      </Text>
      <Text>
        If you&apos;d like to try again, tidy up your prompt, and give it
        another shot.
      </Text>
    </Alert>
  );
};
