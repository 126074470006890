import { useCreateScheduleFromTemplate } from '@/hooks/useCreateScheduleFromTemplate';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useNavigateToSchedule } from '@/hooks/useNavigateToSchedule';
import { useScheduleTemplates } from '@/hooks/useScheduleTemplates';
import { useAddContentModalStore } from '@/pages/Schedule/components';

export const useCreateScheduleFromTemplateLauncher = () => {
  const { navigateToSchedule } = useNavigateToSchedule();
  const { createScheduleFromTemplate } = useCreateScheduleFromTemplate();
  const { open: openAddContentModal } = useAddContentModalStore();
  const { fetchQuery } = useScheduleTemplates();

  useLauncherAction('schedule-create', async ({ template: templateId }) => {
    const templates = await fetchQuery();

    const template = templates.find((item) => item.id === templateId);

    createScheduleFromTemplate(
      { templateId },
      {
        onSuccess: (schedule) => {
          navigateToSchedule(schedule, { replace: true });
          openAddContentModal({
            launchState: 'create-schedule-from-template',
            headerIcons: template?.headerIcons,
          });
        },
      }
    );
  });
};
