import { useState } from 'react';
import { CategorySelectContext } from './CategorySelectContext';
import type { Category, CategorySelectContextValue } from './types';

type Page = 'index' | 'add' | 'edit';

type Props = {
  children: JSX.Element | ((value: CategorySelectContextValue) => JSX.Element);
};
export const CategorySelectContextProvider = ({ children }: Props) => {
  const [page, setPage] = useState<Page>('index');
  const [category, setCategory] = useState<Category | null>(null);

  const value: CategorySelectContextValue = {
    page,
    category,
    addCategoryClick: () => setPage('add'),
    editCategoryClick: (category: Category) => {
      setPage('edit');
      setCategory(category);
    },
    goBack: () => {
      setPage('index');
      setCategory(null);
    },
  };

  return (
    <CategorySelectContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </CategorySelectContext.Provider>
  );
};
